'use client';

import { Button } from '@spikemark/ui/components/ui/button';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
const redirectTimeoutInSeconds = 5;

// TODO partially working, but needs to be at the root of the app dir, which requires a root layout alongside it
// but by doing that, the (payload) dir complains as its layout expects to be root also
export default function NotFoundPage() {
  const router = useRouter();
  const [progress, setProgress] = useState(0);
  const [secondsLeft, setSecondsLeft] = useState(redirectTimeoutInSeconds);
  useEffect(() => {
    const interval = 10; // Update progress every 100ms
    const totalIntervals = redirectTimeoutInSeconds * 1000 / interval;
    let currentInterval = 0;
    const progressInterval = setInterval(() => {
      currentInterval++;
      setProgress(currentInterval / totalIntervals * 100);
      setSecondsLeft(redirectTimeoutInSeconds - Math.floor(currentInterval * interval / 1000));
    }, interval);
    const timer = setTimeout(() => {
      clearInterval(progressInterval);
      router.push('/');
    }, redirectTimeoutInSeconds * 1000);
    return () => {
      clearTimeout(timer);
      clearInterval(progressInterval);
    };
  }, [router]);
  return <div className="h-screen w-screen relative grid place-items-center" data-sentry-component="NotFoundPage" data-sentry-source-file="not-found.tsx">
      <video autoPlay loop muted className="absolute h-full w-full object-cover" src="/golf_swing_miss.mp4" />

      <div className="relative flex flex-col p-8 justify-center items-center gap-8 bg-gray-400/30 rounded-xl">
        <h1 className="text-4xl font-bold">Page Not Found</h1>

        <p>{`You will be redirected to the homepage in ${secondsLeft} seconds.`}</p>

        <div className="w-full max-w-xs bg-gray-200 h-2">
          <div className="bg-blue-500 h-2" style={{
          width: `${progress}%`
        }}></div>
        </div>

        <Button variant={'default'} data-sentry-element="Button" data-sentry-source-file="not-found.tsx">
          <Link href="/" data-sentry-element="Link" data-sentry-source-file="not-found.tsx">Or click here to go there immediately.</Link>
        </Button>
      </div>
    </div>;
}